/*@jsxRuntime automatic @jsxImportSource react*/
import {Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs} from "react/jsx-runtime";
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return _jsxs(_Fragment, {
    children: [_jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Your Credit History is the aggregate of your fulfilled and outstanding liabilities."
      })
    }), "\n", _jsx(_components.p, {
      children: "Credit history also covers the history of your payment discipline, which includes not only the fulfilled, but also your current obligations and how accurately and timely the undertaken obligations are being fulfilled."
    }), "\n", _jsx(_components.p, {
      children: "Companies accumulate credit history of their clients and use it for new contracts or renewal of existing agreements."
    }), "\n", _jsxs(_components.p, {
      children: [_jsx(_components.strong, {
        children: "Your credit history is highly important."
      }), " For example, in case of positive credit history, a telecommunications company might approve sale of your chosen product or service on deferred terms, or you might be able to enjoy better terms and conditions on your loan from a leasing company when purchasing your chosen vehicle."]
    }), "\n", _jsx(_components.p, {
      children: "In case if your credit history is positive, companies might be interested in providing the required service on cash-on-delivery terms or selling your selected product on deferred terms. In case of positive credit history, you might also enjoy more favorable conditions for your payment by installments."
    }), "\n", _jsx(_components.p, {
      children: "In case if your credit history is negative, companies might refuse to enter into agreement with you or might provide their services under other and less favorable terms and conditions, such as purchase of the product on deferred terms with a larger first installment or at higher interest rates."
    }), "\n", _jsx(_components.p, {
      children: "A good example might be a mortgage loan where the amount of the loan is 30,000 EUR and the loan term is 25 years. On such terms, in case the lender increases the interest rate by just 1%, you will pay approximately 5,000 EUR extra."
    }), "\n", _jsx(_components.p, {
      children: _jsx(_components.strong, {
        children: "Each individual builds their credit history themselves, and they are responsible for the impact of credit history on their current and future well-being."
      })
    })]
  });
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? _jsx(MDXLayout, Object.assign({}, props, {
    children: _jsx(_createMdxContent, props)
  })) : _createMdxContent(props);
}
export default MDXContent;
